import React from "react";
import './AddForms.css'

class AddItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isShown: false
        }
        this.submitShown = this.submitShown.bind(this);
    }

    getInput = el => {
        this.input = el;
    }

    submitShown = () => {
        this.setState({isShown: true});
    }

    handleClick = () => {
        if (!this.input.value) {
            return
        }
        this.props.addItem(this.props.count, this.input.value, this.props.count);
        this.setState({isShown: false});
        this.count++;
    }

    submitButton = <>
                        <input
                            className='input_add-item'
                            type='text'
                            ref={this.getInput}
                            placeholder='__________________________________'
                        />
                        <button className='button_submit' onClick={this.handleClick}>Submit</button>
                    </>

    addButton = <>
                    <button className='button_add-item' onClick={this.submitShown}>
                        <svg className='add-symbol' xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <path d="M14 7H9V2C9 1.448 8.552 1 8 1C7.448 1 7 1.448 7 2V7H2C1.448 7 1 7.448 1 8C1 8.552 1.448 9 2 9H7V14C7 14.552 7.448 15 8 15C8.552 15 9 14.552 9 14V9H14C14.552 9 15 8.552 15 8C15 7.448 14.552 7 14 7Z" fill="#5E6C84"/>
                        </svg>
                        Add card
                    </button>
                </>

    render() {

        return (
            <div className='add-item'>
                {this.state.isShown ? this.submitButton : this.addButton}
            </div>
        )

    }

}

export default AddItem